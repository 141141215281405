import { Box, Flex, Grid, Text } from "@mantine/core";
import React from "react";
import ContactFormComp from "./ContactFormComp";
import PhoneSvg from "../../../../public/assets/svgs/PhoneSvg";
import EmailSvg from "../../../../public/assets/svgs/EmailSvg";
import InstaSvg from "../../../../public/assets/svgs/InstaSvg";
import { anta } from "@/app/fonts";
import CoreChips from "@/components/core-components/core-chips/CoreChips";

const ContactUsComp = () => {
  return (
    <Box id="contact" py={{ xs: 60, base: 20 }}>
      <Flex justify="center" mb={8}>
        <CoreChips title="Contact" />
      </Flex>
      <Text
        ta="center"
        fz={{ xs: "40px", base: "32px" }}
        fw={600}
        lh="normal"
        c="white"
        mb="32px"
        className={anta.className}
      >
        Ready to Discuss Your Next Project?
      </Text>
      <Box
        bg={{
          xs: "background.2",
          base: "transparent",
        }}
        bd="1px solid background.5"
        py={{ sm: 24, base: 16 }}
        px={{ sm: 24, base: 16 }}
        style={{
          border: "linear-gradient(180deg, #001B42 100%, #002D6F 100%)",
          borderRadius: "16px",
        }}
      >
        <Grid align="center">
          <Grid.Col span={{ sm: 5, base: 12 }}>
            <Flex direction="column" gap={24} align="start">
              <Text
                c="white"
                fw={500}
                size="36px"
                lh="normal"
                className={anta.className}
              >
                Let us transform your digital presence
              </Text>
              <Text c="#C0C0C0" fw={400}>
                Tell us what you’re looking for and how we can help. Fill out
                the form to collaborate. We will get in touch with you within
                12-24 hours.
              </Text>
              <Box>
                <Flex align="center" gap={24}>
                  <Box>
                    <a
                      aria-label="Instagram"
                      href="https://www.instagram.com/vintsltn/"
                      target="__blank"
                    >
                      <InstaSvg />
                    </a>
                  </Box>
                  <Box>
                    <a
                      href="mailto:vintsolution@gmail.com"
                      aria-label="Email Address"
                    >
                      <EmailSvg />
                    </a>
                  </Box>
                  <Box>
                    <a href="tel:+16476764218" aria-label="Phone Number">
                      <PhoneSvg />
                    </a>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ sm: 7, base: 12 }}>
            <Box mx="auto">
              <ContactFormComp />
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUsComp;
